<template>
  <div class="exc-wrapper">
    <div class="section-practice">
      <p class="header">随堂习题与反馈</p>

      <div class="teacher">
        <div class="info">
          <img
            src="https://ptegofeifan.oss-accelerate.aliyuncs.com/go/admin_file_161803074362070300162.png"
            alt=""
          />
          <div>老师反馈</div>
        </div>
        <div class="body">
          <div class="no-data">提交练习后即可获得老师反馈</div>
        </div>
      </div>

      <div class="list">
        <div v-for="group of practiceList" :key="group.id" class="group-item">
          <div class="group-name">{{ group.tryName }}</div>
          <ul v-if="group.try_list.length">
            <li v-for="(item, index) in group.try_list" :key="item.id">
              <div class="content">
                <span class="name">{{ index + 1 }}. {{ item.gre_name }}</span>
                <div class="tag">
                  <span class="id">#{{ item.gre_id }}</span>
                  <span>{{ item.gre_cate_name }}</span>
                </div>
              </div>

              <template v-if="isBuy">
                <template v-if="item.try_status == 2">
                  <span class="do-homework" @click="handleExercise(item)">
                    去做题
                  </span>
                </template>
                <template v-else>
                  <span class="do-homework">
                    <i class="iconfont icon-finish"></i>
                  </span>
                </template>
              </template>
              <i class="iconfont icon-suoding" v-else />
            </li>
          </ul>
        </div>

        <div class="pagination">
          <el-pagination
            :current-page="page"
            :total="total"
            :page-size="pageSize"
            layout="prev, pager, next"
            @current-change="handlePageChange"
          />
        </div>
      </div>
    </div>
    <div class="submit">
      <div class="empty"></div>
      <div class="text">练习全部做完才可以提交哦</div>
      <div class="button">
        <el-button
          type="primary"
          size="small"
          :disabled="!isAllFinish"
          @click="handleSubmit"
        >
          提交批改
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { apiCourseTrySubmit } from '@/api/IELTScourse'
import { numToCn } from '@/utils/num'

export default {
  props: {
    info: {
      type: Object,
      default: () => ({})
    },
    chapterList: {
      type: Array,
      default: () => []
    },
    isBuy: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      page: 1,
      pageSize: 10
    }
  },
  computed: {
    total() {
      return this.list.length
    },
    isAllFinish() {
      return this.list.some((item) => item.try_status !== 2)
    },
    list() {
      return (this.chapterList || []).reduce((list, group, index) => {
        return list.concat(
          group.try_list.map((item) => ({
            ...item,
            groupName: group.name,
            groupId: group.id,
            tryName: `章节${numToCn(index + 1)}随堂练习（共${group.try_list
              .length || 0}题）`
          }))
        )
      }, [])
    },
    practiceList() {
      return this.list
        .slice((this.page - 1) * this.pageSize, this.page * this.pageSize)
        .reduce((list, item) => {
          let group = list.find((m) => item.groupId === m.id)
          if (group) {
            group.try_list = [].concat(group.try_list || []).concat([item])
          } else {
            group = {
              id: item.groupId,
              name: item.groupName,
              tryName: item.tryName,
              try_list: [].concat([item])
            }
            list.push({
              ...group
            })
          }
          return list
        }, [])
    }
  },
  methods: {
    handlePageChange(page) {
      this.page = page
    },
    handleExercise(item) {
      this.$router.push({
        name: 'Exercises',
        query: {
          from: 'course',
          clid: item.gre_cate,
          practiceId: item.gre_id,
          chapterId: item.chapter_id,
          courseId: item.course_id,
          tryId: item.id
        }
      })
    },

    async handleSubmit() {
      const { code, msg } = await apiCourseTrySubmit({
        course_id: this.info.course_id
      })
      if (code === 200) {
        this.$message.success(msg)
      }
    }
  }
}
</script>

<style scoped lang="scss">
$grayFont: #a5a5ac;
.exc-wrapper {
  font-size: 14px;
  .section-practice {
    color: #ffffff;
    margin-top: 19px;
    .header {
      padding-bottom: 6px;
      border-bottom: 1px solid #676767;
      position: relative;
      font-size: 14px;
      font-weight: bold;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 70px;
        height: 1px;
        background-color: #fff;
      }
    }
    .teacher {
      padding: 20px 28px 16px 28px;
      display: flex;
      align-items: center;
      background-color: #5c5c69;
      border-radius: 5px;
      margin-top: 10px;
      .info {
        text-align: center;
        > img {
          width: 46px;
          height: 46px;
          border-radius: 50%;
        }
        > div {
          margin-top: 6px;
          color: #aeaeb4;
        }
      }
      .body {
        flex: 1;
        text-align: center;
        .no-data {
          color: #aeaeb4;
        }
      }
    }
    .list {
      margin-top: 20px;
      border-radius: 5px;
      background-color: #5c5c69;
      padding: 20px 26px 16px 26px;
      .group-item {
        margin-bottom: 15px;
        .group-name {
          padding: 10px 0;
          border-bottom: 1px solid #676767;
        }

        > ul {
          padding-left: 16px;
          > li {
            display: flex;
            padding: 14px 0;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #676767;
            .content {
              flex: 1;
              display: flex;
              align-items: center;
              .name {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 400px;
              }
              .tag {
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                .id {
                  background-color: #ebebf2;
                  color: #a4afb7;
                }
                > span {
                  min-width: 50px;
                  display: inline-block;
                  line-height: 20px;
                  text-align: center;
                  background-color: #c1e6fe;
                  border-radius: 10px;
                  color: #345d7f;
                  font-size: 12px;
                  margin-left: 10px;
                }
              }
            }
            .do-homework {
              text-align: right;
              cursor: pointer;
              margin-left: 50px;
              > i {
                color: #36c626;
                font-size: 20px;
                line-height: 1;
              }
            }
          }
        }
      }
    }
  }
  .submit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 23px;
    .empty {
      flex: 1;
    }
    .text {
      color: $grayFont;
      margin-right: 12px;
    }
    .button {
      ::v-deep .el-button {
        background-color: #3b86ff;
        border-color: #3b86ff;
        &:disabled {
          opacity: 0.6;
        }
      }
    }
  }

  .pagination {
    text-align: center;
    ::v-deep .el-pagination {
      > button {
        background-color: transparent;
        color: #b6b6bc;
        &:disabled {
          color: #4a4a57;
        }
      }
      .el-pager {
        > li {
          background-color: transparent;
          color: #b6b6bc;
          &.active {
            color: #ff9800;
          }
        }
      }
    }
  }
}
</style>
