<template>
  <div class="wrap">
    <div class="block">
      <div class="title"><span>老师讲解</span></div>
      <div class="content teacher">
        <img :src="courseInfo.teacher_info.img_url" alt="" />
        <div class="info">
          <div>{{ courseInfo.teacher_info.name }}</div>
          <div class="audio">
            <Audio :url="info.audio_url" :currentId="0" v-if="info.audio_url" />
          </div>
        </div>
      </div>
    </div>

    <div class="block" v-if="isBuy">
      <div class="title"><span>录音</span></div>
      <div class="content audio" v-loading="audioLoading">
        <div class="audio-body" @click="handleToggleRecord">
          <i
            class="f-icon "
            :class="[recording ? 'icon-Erase' : 'icon-Microphone']"
          />
        </div>
      </div>
    </div>

    <div class="block">
      <div class="title"><span>练习记录</span></div>
      <div class="content list">
        <div class="item" v-for="item of list" :key="item.id">
          <img :src="item.user_info.headimgurl" alt="" />
          <div class="info">
            <div class="name">
              <span>{{ item.user_info.name }}</span>
              <span class="target">{{ item.user_info.user_goal }}</span>
            </div>
            <div class="item-audio">
              <Audio
                :url="item.audio_url"
                :currentId="0"
                v-if="item.audio_url"
              />
            </div>
            <div class="time">
              <span>{{ item.add_time }}</span>
              <span class="tools">
                <i class="iconfont icon-del" @click="handleDel(item)"></i>
              </span>
            </div>
          </div>
        </div>

        <More
          :page="page"
          :pageSize="10"
          :loading="loading"
          :totalPage="Math.min(10, totalPage)"
          @load="handleLoad"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Audio from '@/components/Audio/Audio.vue'
import More from '@/components/more'
import Recorder, { ENCODE_TYPE } from 'recorderx'
import * as upload from '@/utils/upload'

import {
  apiCourseVipAudio,
  apiCourseVipAudioList,
  apiCourseAudioDel
} from '@/api/IELTScourse'

//生成随机数
const getMath = () => {
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
}

export default {
  components: {
    Audio,
    More
  },
  props: {
    courseInfo: {
      type: Object,
      default: () => ({})
    },
    info: {
      type: Object,
      default: () => ({})
    },
    chapterList: {
      type: Array,
      default: () => []
    },
    isBuy: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      recording: false,

      audioValue: null,
      recorderUrl: '',
      audioLoading: false,

      list: [],
      page: 1,
      totalPage: 0,
      loading: false
    }
  },
  watch: {
    info: {
      immediate: true,
      handler() {
        if (this.info.id) {
          this.fetchList()
        }
      }
    }
  },
  created() {
    if (!navigator.mediaDevices.getUserMedia) {
      this.supportRecorder = false
      this.$layer.msg("<div class='error-icon'></div><div>当前不支持录音</div>")
      return
    }
  },
  methods: {
    // 开始录音
    startRecorder() {
      if (this.recording) {
        return
      }
      this.rc = new Recorder()
      this.$nextTick(() => {
        this.rc
          .start()
          .then(() => {
            console.log('start recording')
          })
          .catch((error) => {
            console.log('Recording failed.', error)
          })
        this.recording = true
      })
    },
    // 暂停音频
    pauseRecorder() {
      this.rc.pause()
      this.recording = false

      this.getRecorder()
    },
    // 获得音频
    getRecorder() {
      const wav = this.rc.getRecord({
        encodeTo: ENCODE_TYPE.WAV
        // compressible: true
      })
      this.audioValue = wav

      this.uploadAudio()
    },
    uploadAudio() {
      this.audioLoading = true

      const name = getMath() + getMath() + getMath() + '.wav'
      upload.file({
        file: this.audioValue,
        fileName: name,
        onSuccess: async ({ data }) => {
          this.recorderUrl = data.oss_url

          await apiCourseVipAudio({
            course_id: this.courseInfo.id,
            chapter_id: this.info.id,
            audio_url: this.recorderUrl
          })

          this.audioLoading = false

          this.handleLoad(1)
        }
      })
    },
    handleToggleRecord() {
      if (this.recording) {
        this.pauseRecorder()
      } else {
        this.startRecorder()
      }
    },

    async fetchList() {
      this.loading = true
      const { data } = await apiCourseVipAudioList({
        page: this.page,
        page_size: 10,
        course_id: this.info.course_id,
        chapter_id: this.info.id
      })
      this.list = this.page === 1 ? data.list : this.list.concat(data.list)
      this.totalPage = data.pager.page_cnt
      this.loading = false
    },
    handleLoad(page) {
      this.page = page
      this.fetchList()
    },

    handleDel(item) {
      this.$confirm('是否要删除该练习', '删除练习', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(async () => {
        const { code, msg } = await apiCourseAudioDel({
          id: item.id
        })
        if (code !== 200) {
          return false
        }

        this.$message.success(msg)
        this.handleLoad(1)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.wrap {
  .block {
    margin-top: 24px;
    .title {
      border-bottom: 1px solid #676767;
      color: #fff;
      > span {
        display: inline-block;
        padding: 6px 0;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 1px;
          background-color: #ffffff;
        }
      }
    }
    .content {
      padding: 12px 14px;
      background-color: #5c5c69;
      border-radius: 5px;
      margin-top: 10px;
      min-height: 70px;
      color: #fff;
      ::v-deep .audio-content {
        background-color: #fff;
      }
    }
  }
  .teacher {
    display: flex;
    > img {
      width: 46px;
      height: 46px;
      border-radius: 50%;
      object-fit: cover;
    }
    .info {
      margin-left: 20px;
      .audio {
        margin-top: 6px;
      }
    }
  }
  .audio {
    display: flex;
    align-items: center;
    justify-content: center;
    .audio-body {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      background-color: #3b86ff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      > i {
        font-size: 20px;
      }
    }
  }
  .list {
    background-color: #4a4a58 !important;
    padding: 0 !important;
    .item {
      display: flex;
      padding: 16px 6px;
      border-bottom: 1px solid #5c5c69;
      > img {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        object-fit: cover;
      }
      .info {
        flex: 1;
        margin-left: 12px;
        .name {
          color: #fff;
          .target {
            margin-left: 12px;
            opacity: 0.6;
          }
        }
        .item-audio {
          margin-top: 6px;
        }
        .time {
          margin-top: 12px;
          color: #a5a5ac;
          .tools {
            margin-left: 24px;
            > i {
              cursor: pointer;
              color: #a5a5ac;
            }
          }
        }
      }
    }
  }
}
</style>
