<template>
  <div class="chapter-list">
    <div class="title">
      <span class="title-1">课程列表</span>
      <span class="title-2">(1/{{ list.length }})</span>
    </div>
    <div class="class-list">
      <div
        class="active-class"
        v-for="item of list"
        :key="item.id"
        :class="{ active: chapterId == item.id }"
        @click="handleChapter(item)"
      >
        <template>
          <i class="iconfont icon-play1" v-if="isBuy || item.free_try == 1"></i>
          <i class="iconfont icon-suoding" v-else />
        </template>
        <span class="text">{{ item.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { apiChapterList } from '@/api/IELTScourse'

export default {
  props: {
    chapterList: {
      type: Array,
      default: () => []
    },
    isBuy: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    chapterId() {
      return this.$route.query.chapterId
    },
    list() {
      return this.chapterList || []
    }
  },
  methods: {
    handleChapter(item) {
      const { skillId } = this.$route.query
      this.$router.replace({
        name: 'IELTSchapterDetail',
        query: {
          skillId,
          chapterId: item.id
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
$grayFont: #a5a5ac;
.chapter-list {
  margin-bottom: 38px;
  background: #5d5d69;
  padding-bottom: 8px;
  border-radius: 5px;
  .title {
    padding: 10px 0 7px 16px;

    .title-1 {
      color: #ffffff;
      font-size: 16px;
    }

    .title-2 {
      font-size: 12px;
      color: $grayFont;
      margin-left: 4px;
    }
  }
  .class-list {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    max-height: 500px;
    overflow-y: auto;

    .active-class {
      width: 96%;
      height: 32px;
      margin: 2px 4px;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      color: #ffffff;
      cursor: pointer;
      .iconfont {
        margin-left: 10px;
      }
      .text {
        flex: 1;
        margin-left: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &.active {
        background-color: #3b86ff;
      }
    }
  }
}
</style>
